.modal-container {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 42px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.proforma-link-text {
    width: 65%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-image-container {
    display: flex;
    justify-content: center;
}

.modal-close-icon {
    position: absolute;
    right: 42px;
    width: 14px;
    cursor: pointer;
}