#root {
  height: 100%;
}
html,
body {
  height: 100%;
}
.homeContainer {
  min-height: 100vh;
}

.commentFilter {
  display: grid;
  grid-template-columns: auto 85px 140px;
  margin-top: 20px;
}

.commentLeft {
  display: flex;
  align-items: center;
}

.commentBtn {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #ff6d00;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.commentCounter {
  font-size: 10pt;
}

.commentBtn.primary-lg {
  width: 100%;
  height: 44px;
}

.commentBtn.primary {
  width: 81px;
  height: 36px;
}

.commentBtn.primaryDisabled {
  width: 81px;
  height: 36px;
  background-color: #c8cbcc;
}

.commentBtn.secondary {
  background-color: transparent;
  color: #37474f;
  height: 36px;
}

.commentBtn.secondaryDisabled {
  background-color: transparent;
  color: #c8cbcc;
  height: 36px;
}

.inputComment {
  margin-left: 0px;
  margin-bottom: 15px;
  accent-color: #37474f;
}

.commentBtn:hover {
  opacity: 0.9;
}

.inventoryBtnIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  font-weight: bold;
}

.inventoryNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inventoryNotFoundContainer {
  width: 180px;
  margin-top: 128px;
  text-align: center;
  font-weight: normal;
}

.commentNotFoundTittle {
  font-size: 14px;
  margin-bottom: 32px;
}

.select {
  display: flex;
  align-items: center;
}

.select h2 {
  color: #37474f;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 12px;
}

.select select {
  width: 180px;
  height: 30px;
  border: 1px solid #c8cbcc;
  color: #6c7980;
  appearance: none;
  background: url("../../assets/arrowSelect.svg") no-repeat right;
  background-position-x: 155px;
  text-indent: 8px;
  font-size: 16px;
}

.inventoryTable {
  height: 90%;
}

.inventoryTable td {
  height: 80px;
}

.inventoryTable {
  margin-top: 64px;
  color: #37474f;
}

.inventoryTable .imagen {
  width: 60px;
  height: 60px;
  background-color: #c8cbcc;
}

.inventoryTable .imagen img {
  width: 100%;
  height: 100%;
  background-color: #c8cbcc;
}

.inventoryTable .comentProduct {
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}

.inventoryTable .comentProduct .comentProductIcon {
  width: 10px;
  height: 10px;
  margin-right: 6px;
}

.inventoryTable .comentProduct span {
  font-size: 14px;
}

.inventoryTable .category {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 8px;
}

.inventoryTable .categoryItem {
  height: 24px;
  border: 1px solid #c8cbcc;
  border-radius: 4px;
  text-align: center;
  margin: 5px 0;
  padding: 2px;
}

.inventoryTable .categoryItem span {
  font-size: 14px;
}

.inventoryTable .tableBodyItem .tableActions {
  display: flex;
  justify-content: space-evenly;
}

.tableBodyItemFlex {
  display: flex;
}

.productTdFlex {
  display: flex;
  flex-direction: column;
  flex-basis: 18%;
}

.imageTdFlex {
  display: flex;
  flex-basis: 24%;
  justify-content: flex-start;
}

.commentTdFlex {
  display: flex;
  flex-basis: 28%;
  justify-content: flex-start;
  flex-wrap: wrap;
  white-space: normal;
}

.buttonTdFlexShow {
  display: flex;
  flex-basis: 24%;
  justify-content: center;
  align-items: flex-start;
  white-space: normal;
}

.buttonTdFlexNotShow {
  display: flex;
  flex-basis: 13%;
  justify-content: center;
  align-items: flex-start;
  white-space: normal;
}

.topMarginClass {
  margin-top: 8px;
}

.dateTdFlex {
  display: flex;
  flex-basis: 10%;
  justify-content: flex-start;
  flex-wrap: wrap;
  white-space: normal;
}

.imageContainerTdFlex {
  display: flex;
  margin-top: 8px;
  margin-left: 8px;
}

.checkboxFlex {
  display: flex;
  align-items: center;
  flex-basis: 4%;
  justify-content: center;
}

.contentContainerTdFlex {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
}

.titleText {
  font-size: 12pt;
  font-weight: normal;
  text-transform: none;
}

.dateText {
  font-size: 12pt;
  font-weight: normal;
  text-transform: none;
  margin-top: 8%;
  margin-left: 21%;
}

.contentText {
  font-size: 10pt;
  font-weight: normal;
  text-transform: none;
}

.contentProductText {
  font-size: 10pt;
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 8px;
  margin-right: 3%;
}

.contentCommentText {
  font-size: 10pt;
  font-weight: normal;
  text-transform: none;
  margin-top: 8px;
  margin-right: 8%;
}

.buttonContainer {
  margin-top: 8px;
}

.commentImageCircle {
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.5%;
  margin-left: 1%;
}

.flexContainerRow {
  display: flex;
  flex-direction: row;
}

.flexContainerHighLightRow {
  display: flex;
  flex-direction: row;
  background-color: #FFF8F2;
}

.flexContainewRowChecked {
  display: flex;
  flex-direction: row;
  background-color: #c8cbcc;
}

table tbody tr:hover {
  background-color: #c8cbcc;
  cursor: pointer;
}

thead {
  width: 100% !important;
}

tbody {
  height: 100%;
}
