.accordion {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    width: min-content;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.accordion-content {
    display: flex;
}