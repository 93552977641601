.headContainer {
  margin: 0 32.5px;
  height: 100%;
}

.headContainer {
  color: #37474f;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.headContainer {
  list-style: none;
}

.headContainer {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
  margin-top: 32px;
  font-size: 20px;
  color: #6c7980;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.headContainer {
  color: #ff6d00;
}
