@keyframes ldio-3b1ir8li0qi {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  
  .loadingDiv{
    position: fixed;
    z-index: 99;
    top:0; 
    bottom: 0;
    left: 0; 
    right: 0; 
    background: #000000CD;
  }
  .ldio-3b1ir8li0qi div {
    left: 94px;
    top: 48px;
    position: absolute;
    animation: ldio-3b1ir8li0qi linear 1s infinite;
    background: #ee6123;
    width: 12px;
    height: 24px;
    border-radius: 6px / 12px;
    transform-origin: 6px 52px;
  }.ldio-3b1ir8li0qi div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #ee6123;
  }
  .loadingio-spinner-spinner-i7ozunbmji {
    width: 200px;
    height: 200px;
    overflow: hidden;
    background: #f1f2f3;
    position: fixed;
    left: 50%;
    margin-left: -100px;
    top: 50%;
    margin-top: -100px;
    border-radius:40px;
    z-index: 99;
    
  }
  .ldio-3b1ir8li0qi {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-3b1ir8li0qi div { box-sizing: content-box; }
  /* generated by https://loading.io/ */