.search input[type="text"] {
    font-size: 16px;
    border: 1px solid #c8cbcc;
    text-indent: 25px;
    padding: 5px;
    border-radius: 2px;
    font-weight: 400;
}

.search {
    position: relative;
    margin-right: 32px;
}

.searchIcon {
    position: absolute;
    width: 16px;
    height: 18px;
    top: 5px;
    bottom: 0;
    left: 8px;
    color: #6c7980;
    font-weight: bold;
}

.searchText {
    height: 30px;
}