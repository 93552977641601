.changePassword-general-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0px 15px 15px 15px;
}

.changePassword-title {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    white-space: nowrap;
    user-select: none;
}

.changePassword-icon {
    margin-top: -5px;
}

.changePassword-input-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    width: 100%;
}

.changePassword-input-label {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.changePassword-input {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
    padding: 0px 10px;
}

.changePassword-button-container {
    display: flex;
    justify-content: flex-end;
}

.changePassword-button {
    background: #FF6D00;
    border: 0;
    box-shadow: 0px 2px 8px rgb(9 128 225 / 10%);
    border-radius: 4px;
    padding: 5px 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    transition: 0.3s;
    margin: 0px;
}

.changePassword-button:hover {
    cursor: pointer;
    background: #999999;
}