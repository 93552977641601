.backgroundContainer {
    background: linear-gradient(116.82deg, #37474F 0%, #1F2526 100%);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer {
    background: #FFFFFF;
    border-radius: 4px;
    width: 45.5%;
    height: 54.1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.passwordRecoveryContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 13px;
}

.loginButtonContainer {
    width: 80%;
    display: flex;
    justify-content: flex-end;
}
.loginButton {
    background: #FF6D00;
    border: 0;
    box-shadow: 0px 2px 8px rgb(9 128 225 / 10%);
    border-radius: 4px;
    padding: 10px 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    transition: 0.3s;
}

.loginButton:hover {
    cursor: pointer;
    background: #999999;
}

.passwordRecovery {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.passwordRecovery:hover {
    text-decoration: underline;
    cursor: pointer;
}

.loginInput {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
    padding: 0px 10px;
}

.loginInputLabel {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.loginInputContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
}