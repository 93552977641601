/* Customize the label (the container) */

.container {
    display: flex;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #37474F;
    flex-direction: row;
    align-items: center;
    height: 0px;
}


/* Hide the browser's default checkbox */

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #37474F;
}


/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.container input:checked~.checkmark {
    background-color: #37474F;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.container input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}