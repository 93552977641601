.mainContainer {
    margin: 0 40px;
    height: 89vh;
}

.topContainer {
    display: grid;
    grid-template-columns: 60% 30%;
    grid-template-areas: "general publish " "productDetails productDetails";
    justify-content: space-between;
    height: 46vh;
    row-gap: 20px;
}

.generalInfoContainer {
    grid-area: general;
    padding-right: 5px;
}

.publishContainer {
    grid-area: publish;
}

.productDetail {
    grid-area: productDetails;
    height: 36vh;
    overflow-y: auto;
    padding-right: 5px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2vh;
}

.newProductTitle {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    text-transform: uppercase;
}

.sectionTitle {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
}

.sectionSubTitle {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 12px;
}

.customInput {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    color: #6c7980;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 5px 10px;
    resize: none;
    margin-top: 5px;
}

.publishSubContainer {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0px 20px;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 37vh;
}

.selectContainer {
    display: flex;
    align-items: baseline;
}

.selectContainer2 {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.publishButton {
    background: #ff6d00;
    box-shadow: 0px 2px 8px rgb(55 71 79 / 10%);
    border-radius: 4px;
    border: 0;
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 0px;
    cursor: pointer;
}

.saveDraftButton {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 0px;
    border: 0;
    width: 100%;
    background: #ffffff;
    cursor: pointer;
}

.tab-content {
    height: 23vh;
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    margin-top: -12px;
    overflow-y: auto;
}

.charCounterContainer {
    display: flex;
    justify-content: flex-end;
}

.charCounter {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    opacity: 0.5;
}

.itemContainer {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1px 5px;
    color: #37474f;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 10px;
}

.itemListContainer {
    display: flex;
    overflow-x: auto;
    margin-top: 1vh;
}

.tabContainer {
    display: flex;
    justify-content: space-around;
    padding: 0px 15px 15px 15px;
}

.tabInnerContainer {
    width: 45%;
}

.checkInput {
    margin-left: 20px;
}

.dateContainer {
    display: flex;
    justify-content: space-evenly;
}

.dateSelectorContainer {
    display: flex;
    align-items: center;
}

.skuInputContainer {
    display: flex;
    align-items: center;
}

.secondImageContainer {
    width: 100px;
    height: 100px;
    border: 1px solid #c8cbcc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}

.fileInput label {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    color: #6c7980;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 5px 10px;
    resize: none;
    cursor: pointer;
}

.mainImage {
    width: 197px;
    height: 197px;
    object-fit: contain;
    border: 1px solid #c8cbcc;
    margin-top: 1vh;
}

.secondImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border: 1px solid #c8cbcc;
}

.closeImagen {
    position: absolute;
    top: -6px;
    right: -6px;
    cursor: pointer;
    border: 1px solid #c8cbcc;
    border-radius: 100%;
    padding: 2px;
    background: #fff;
}

.galeryContainer {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    column-gap: 10px;
    row-gap: 10px;
    margin-top: 2vh;
}

.tabContainerSingle {
    display: flex;
    padding: 0px 15px 15px 15px;
}

.attributesContainer {
    padding: 37px 32px;
}

.subattributesContainer {
    padding: 24px 35px;
}

.helperInfoContainer {
    background-color: #ececec;
    border-color: #e0e0e0 !important;
    border: #000000 !important;
    border-radius: 10px;
    padding: 7px;
    width: 17%;
    margin-top: -25px;
}

.helperInfoContainer2 {
    background-color: #ececec;
    border-color: #e0e0e0 !important;
    border: #000000 !important;
    border-radius: 10px;
    padding: 7px;
    width: 25%;
    align-self: flex-end;
    margin-top: 2%;
}

.h6-info-X {
    margin-top: -15px;
    margin-right: -2px;
}

.continer-text-require {
    display: flex;
    align-items: center;
    align-self: baseline;
}

.container {
    width: auto;
    margin-left: 0px;
}

.helperContainer {
    display: flex;
    align-items: baseline;
}

@media screen and (min-width: 320px) and (max-width: 320px) {}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .publishSubContainer {
        height: 50vh;
    }
    .isFlexColumnG {
        flex-direction: column;
    }
    .isFlexColumnG .sectionSubTitle {
        margin-bottom: 10px;
    }
    .selectspecial {
        margin-top: 5px;
    }
    .helperContainer .sectionSubTitle {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 480px) {
    .mainContainer {
        margin: 0 20px;
    }
    .newProductTitle {
        font-size: 24px;
    }
    .sectionTitle {
        font-size: 18px;
    }
    .topContainer {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "general  " "productDetails " "publish";
        height: auto;
    }
    .tabContainer {
        flex-direction: column;
    }
    .tabInnerContainer {
        width: 100%;
    }
    .isFlexColumn {
        flex-direction: column;
    }
    .isFlexColumn .sectionSubTitle {
        margin-bottom: 10px;
    }
    .selectContainer2 {
        justify-content: start;
    }
    .tab-buttons {
        overflow-x: auto;
        white-space: nowrap;
        margin-bottom: 20px;
    }
    .tab-buttons::-webkit-scrollbar {
        display: none;
    }
    .tab-buttons button {
        font-size: 16px;
        padding: 4px 10px;
        margin-right: 10px;
    }
    .productDetail,
    .tab-content {
        height: auto;
        overflow-y: initial;
    }
    .itemListContainer {
        flex-wrap: wrap;
    }
    .productOuts {
        margin: 10px 0;
    }
    .sectionSubTitle {
        font-size: 12px;
    }
    .inputContainer .sectionSubTitle {
        margin-right: 0;
    }
    .bgActive {
        /* border: 1px solid red; */
        height: 30px;
        background-color: rgba(255, 109, 0, 0.2);
        border-radius: 5px;
    }
    .galeryContainer {
        grid-template-columns: 100px 100px;
    }
    .itemContainer {
        margin-top: 5px;
    }
    .helperInfoContainer {
        width: 100%;
        margin-top: 10px;
    }
    .subattributesContainer {
        padding: 12px 12px;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {}

@media screen and (min-width: 411px) and (max-width: 767px) {}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .topContainer {
        grid-template-columns: 60% 35%;
    }
    .tab-content {
        margin-top: -10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
    .tab-buttons button {
        font-size: 16px;
    }
    .helperInfoContainer {
        width: 27%;
    }
}