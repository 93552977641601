.main-container {
    margin: 0 40px;
    height: 100%;
}

.title-orders {
    color: #37474f;
    font-size: 28px;
    font-weight: 700;
}


/* .option-text {
    color: #6C7980;
    margin-left: 15px;
    cursor: pointer;
} */

.option-text:nth-child(1) {
    margin-left: 0;
}

.option-selected {
    color: #ff6d00;
}

.search-container {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.custom-input {
    border: 1px solid #c8cbcc;
    border-radius: 5px;
    padding: 5px 10px;
    outline: none;
    height: 30px;
}

.column-text {
    color: #37474f;
    font-weight: bold;
    display: flex;
    flex-basis: 25%;
    align-items: center;
}

.column-text:nth-child(2),
.column-text:nth-child(3) {
    margin-left: 32px;
}

.column-text span {
    margin-right: 25px;
}

.filterStatus {
    width: 100%;
}

.table-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.table-column {
    display: flex;
    width: 100%;
    margin-top: 2%;
    border-bottom: 1px solid #c8cbcc;
    color: #37474f;
    justify-content: space-between;
}

.column-name {
    display: flex;
    flex-basis: 14%;
    max-width: 14%;
    justify-content: center;
}

.table-rows {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #37474f;
    font-size: 15px;
    cursor: pointer;
}

.table-row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #c8cbcc;
    margin-top: 1%;
    justify-content: space-between;
}

.row-data {
    display: flex;
    flex-basis: 14%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.actionsOrders {
    display: flex;
}

.select-order-type {
    height: 25px;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    justify-content: center;
}

.pagination>.active>a {
    color: #ff6d00;
}

.pagination>li>a {
    padding: 5px 10px;
    cursor: pointer;
}

.statusOrders {
    margin-left: 8px;
}

.btn-orders-primary {
    border: none;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #ff6d00;
    font-weight: bold;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px;
}

.containerMobileOrders {
    color: #37474f;
}

.cardMobileOrders {
    width: auto;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(55, 71, 79, 0.15);
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 24px;
}

.order-info p {
    font-size: 14px;
    cursor: pointer;
}

.order-info p span {
    font-weight: 700;
    text-transform: uppercase;
}

.itemContainerOrders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px 0;
}

@media screen and (min-width: 320px) and (max-width: 320px) {
    .custom-input {
        width: 110px;
    }
    .order-actions,
    .order-data {
        width: 122px;
    }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
    .custom-input {
        width: revert;
    }
    .order-actions,
    .order-data {
        width: 170px;
    }
}

@media screen and (max-width: 480px) {
    .main-container {
        margin: 0 20px;
    }
    .scrollOptions {
        overflow-x: auto;
        white-space: nowrap;
    }
    .scrollOptions::-webkit-scrollbar {
        display: none;
    }
    .column-text span {
        display: none;
    }
    .column-text:nth-child(1) {
        flex-basis: 100%;
        margin-bottom: 20px;
    }
    .column-text:nth-child(2) {
        margin-right: 20px;
    }
    .column-text:nth-child(2),
    .column-text:nth-child(3) {
        margin-left: 0;
        flex-basis: 43%;
    }
    .actionsOrders {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn-orders-primary {
        margin: 0;
        width: 122px;
        margin-bottom: 8px;
    }
    .statusOrders {
        font-size: 14px;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {}

@media screen and (min-width: 411px) and (max-width: 767px) {
    .custom-input {
        width: 160px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .column-text span {
        margin-right: 10px;
    }
    .column-text:nth-child(1) {
        flex-basis: 27%;
    }
    .column-text:nth-child(2) {
        flex-basis: 36%;
        margin-left: 15px;
    }
    .column-text:nth-child(3) {
        margin-left: 15px;
    }
    .column-name {
        flex-basis: 25%;
    }
    .column-name:nth-child(3) {
        min-width: 25%;
    }
    .column-name:nth-child(6) {
        flex-basis: 40%;
    }
    .row-data {
        font-size: 14px;
    }
    .row-data:nth-child(3) {
        flex-basis: 18%;
        /* border: 1px solid red; */
    }
    .row-data:nth-child(4) {
        flex-basis: 13%;
    }
    .actionsOrders {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .statusOrders {
        margin-left: 4px;
        font-size: 13px;
    }
    .btn-orders-primary {
        margin: 0;
        margin-bottom: 7px;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {}