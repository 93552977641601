.second-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    border: 1px solid #6C7980;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #37474F;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.client-info-table {
    width: 45%;
}

.client-info-table > tbody > tr > td {
    border-bottom: 0;
}

.max-height-table-review {
    overflow: auto;
    height: calc(100vh - 660px);
    min-height: 80px;
}

.total-text {
    font-weight: bold;
    font-size: 20px;
    color: #FF6D00;
}
