@import url(https://fonts.googleapis.com/css2?family=Hind:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
    margin: 0;
    padding: 0;
    font-family: 'Hind', sans-serif;
}


/* width */

::-webkit-scrollbar {
    width: 9px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #ededed;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #C8CBCC;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #b6b7b8;
}

.swal2-confirm {
    background-color: #FF6D00 !important;
}

.headerOptions ul {
    list-style: none;
}

.headerOptions ul li {
    list-style: none;
    display: inline-block;
    margin-right: 20px;
    margin-top: 32px;
    font-size: 20px;
    color: #6c7980;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

.headerOptions ul li.isActive {
    color: #ff6d00;
}

@media screen and (max-width: 480px) {
    .headerOptions {
        margin-top: 20px;
    }
    .headerOptions ul {
        display: inline;
    }
    .headerOptions ul li {
        margin: 0;
        font-size: 14px;
        padding: 5px 10px;
        margin-right: 15px;
    }
    .headerOptions ul li p {
        margin-top: 5px;
    }
    .headerOptions ul li.isActive {
        width: auto;
        height: 30px;
        background-color: rgba(255, 109, 0, 0.2);
        border-radius: 5px;
    }
}
.backgroundContainer {
    background: linear-gradient(116.82deg, #37474F 0%, #1F2526 100%);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.loginContainer {
    background: #FFFFFF;
    border-radius: 4px;
    width: 45.5%;
    height: 54.1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.passwordRecoveryContainer {
    display: flex;
    justify-content: flex-end;
    margin-top: 13px;
}

.loginButtonContainer {
    width: 80%;
    display: flex;
    justify-content: flex-end;
}
.loginButton {
    background: #FF6D00;
    border: 0;
    box-shadow: 0px 2px 8px rgb(9 128 225 / 10%);
    border-radius: 4px;
    padding: 10px 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    transition: 0.3s;
}

.loginButton:hover {
    cursor: pointer;
    background: #999999;
}

.passwordRecovery {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.passwordRecovery:hover {
    text-decoration: underline;
    cursor: pointer;
}

.loginInput {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
    padding: 0px 10px;
}

.loginInputLabel {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.loginInputContainer {
    display: flex;
    flex-direction: column;
    width: 70%;
}
#root {
  height: 100%;
}
html,
body {
  height: 100%;
}
.homeContainer {
  min-height: 100vh;
}

.commentFilter {
  display: grid;
  grid-template-columns: auto 85px 140px;
  margin-top: 20px;
}

.commentLeft {
  display: flex;
  align-items: center;
}

.commentBtn {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #ff6d00;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
}

.commentCounter {
  font-size: 10pt;
}

.commentBtn.primary-lg {
  width: 100%;
  height: 44px;
}

.commentBtn.primary {
  width: 81px;
  height: 36px;
}

.commentBtn.primaryDisabled {
  width: 81px;
  height: 36px;
  background-color: #c8cbcc;
}

.commentBtn.secondary {
  background-color: transparent;
  color: #37474f;
  height: 36px;
}

.commentBtn.secondaryDisabled {
  background-color: transparent;
  color: #c8cbcc;
  height: 36px;
}

.inputComment {
  margin-left: 0px;
  margin-bottom: 15px;
  accent-color: #37474f;
}

.commentBtn:hover {
  opacity: 0.9;
}

.inventoryBtnIcon {
  margin-right: 10px;
  width: 20px;
  height: 20px;
  font-weight: bold;
}

.inventoryNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.inventoryNotFoundContainer {
  width: 180px;
  margin-top: 128px;
  text-align: center;
  font-weight: normal;
}

.commentNotFoundTittle {
  font-size: 14px;
  margin-bottom: 32px;
}

.select {
  display: flex;
  align-items: center;
}

.select h2 {
  color: #37474f;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  margin-right: 12px;
}

.select select {
  width: 180px;
  height: 30px;
  border: 1px solid #c8cbcc;
  color: #6c7980;
  -webkit-appearance: none;
          appearance: none;
  background: url(/static/media/arrowSelect.73c16702.svg) no-repeat right;
  background-position-x: 155px;
  text-indent: 8px;
  font-size: 16px;
}

.inventoryTable {
  height: 90%;
}

.inventoryTable td {
  height: 80px;
}

.inventoryTable {
  margin-top: 64px;
  color: #37474f;
}

.inventoryTable .imagen {
  width: 60px;
  height: 60px;
  background-color: #c8cbcc;
}

.inventoryTable .imagen img {
  width: 100%;
  height: 100%;
  background-color: #c8cbcc;
}

.inventoryTable .comentProduct {
  display: flex;
  align-items: center;
  font-weight: 700;
  cursor: pointer;
}

.inventoryTable .comentProduct .comentProductIcon {
  width: 10px;
  height: 10px;
  margin-right: 6px;
}

.inventoryTable .comentProduct span {
  font-size: 14px;
}

.inventoryTable .category {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-column-gap: 8px;
}

.inventoryTable .categoryItem {
  height: 24px;
  border: 1px solid #c8cbcc;
  border-radius: 4px;
  text-align: center;
  margin: 5px 0;
  padding: 2px;
}

.inventoryTable .categoryItem span {
  font-size: 14px;
}

.inventoryTable .tableBodyItem .tableActions {
  display: flex;
  justify-content: space-evenly;
}

.tableBodyItemFlex {
  display: flex;
}

.productTdFlex {
  display: flex;
  flex-direction: column;
  flex-basis: 18%;
}

.imageTdFlex {
  display: flex;
  flex-basis: 24%;
  justify-content: flex-start;
}

.commentTdFlex {
  display: flex;
  flex-basis: 28%;
  justify-content: flex-start;
  flex-wrap: wrap;
  white-space: normal;
}

.buttonTdFlexShow {
  display: flex;
  flex-basis: 24%;
  justify-content: center;
  align-items: flex-start;
  white-space: normal;
}

.buttonTdFlexNotShow {
  display: flex;
  flex-basis: 13%;
  justify-content: center;
  align-items: flex-start;
  white-space: normal;
}

.topMarginClass {
  margin-top: 8px;
}

.dateTdFlex {
  display: flex;
  flex-basis: 10%;
  justify-content: flex-start;
  flex-wrap: wrap;
  white-space: normal;
}

.imageContainerTdFlex {
  display: flex;
  margin-top: 8px;
  margin-left: 8px;
}

.checkboxFlex {
  display: flex;
  align-items: center;
  flex-basis: 4%;
  justify-content: center;
}

.contentContainerTdFlex {
  display: flex;
  margin-top: 8px;
  flex-direction: column;
  align-items: flex-start;
  margin-left: 12px;
}

.titleText {
  font-size: 12pt;
  font-weight: normal;
  text-transform: none;
}

.dateText {
  font-size: 12pt;
  font-weight: normal;
  text-transform: none;
  margin-top: 8%;
  margin-left: 21%;
}

.contentText {
  font-size: 10pt;
  font-weight: normal;
  text-transform: none;
}

.contentProductText {
  font-size: 10pt;
  font-weight: bold;
  text-transform: uppercase;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-top: 8px;
  margin-right: 3%;
}

.contentCommentText {
  font-size: 10pt;
  font-weight: normal;
  text-transform: none;
  margin-top: 8px;
  margin-right: 8%;
}

.buttonContainer {
  margin-top: 8px;
}

.commentImageCircle {
  width: 48px;
  height: 48px;
  background: white;
  border-radius: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 2.5%;
  margin-left: 1%;
}

.flexContainerRow {
  display: flex;
  flex-direction: row;
}

.flexContainerHighLightRow {
  display: flex;
  flex-direction: row;
  background-color: #FFF8F2;
}

.flexContainewRowChecked {
  display: flex;
  flex-direction: row;
  background-color: #c8cbcc;
}

table tbody tr:hover {
  background-color: #c8cbcc;
  cursor: pointer;
}

thead {
  width: 100% !important;
}

tbody {
  height: 100%;
}

.headContainer {
  margin: 0 32.5px;
  height: 100%;
}

.headContainer {
  color: #37474f;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.headContainer {
  list-style: none;
}

.headContainer {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
  margin-top: 32px;
  font-size: 20px;
  color: #6c7980;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.headContainer {
  color: #ff6d00;
}

.inventoryTitle {
  color: #37474f;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
}

.headerOptions ul li.isActive {
  color: #ff6d00;
}

.headerOptions ul {
  list-style: none;
}

.headerOptions ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
  margin-top: 32px;
  font-size: 20px;
  color: #6c7980;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}

.search input[type="text"] {
    font-size: 16px;
    border: 1px solid #c8cbcc;
    text-indent: 25px;
    padding: 5px;
    border-radius: 2px;
    font-weight: 400;
}

.search {
    position: relative;
    margin-right: 32px;
}

.searchIcon {
    position: absolute;
    width: 16px;
    height: 18px;
    top: 5px;
    bottom: 0;
    left: 8px;
    color: #6c7980;
    font-weight: bold;
}

.searchText {
    height: 30px;
}
.pageTitle {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    display: block;
    margin-bottom: 20px;
}

.welcomeText {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    display: block;
    margin-bottom: 46px;
}

.item-1 {
    grid-area: report;
}

.item-2 {
    grid-area: activity;
}

.item-3 {
    grid-area: orders;
}

.item-4 {
    grid-area: sales;
}

.containerDesktop {
    margin-left: 32px;
    margin-right: 48px;
    /* overflow: visible; */
}

.gridContainer {
    display: grid;
    height: auto;
    width: 100%;
    grid-template-columns: 656px 427px;
    /*grid-template-rows: 274px 274px; */
    grid-column-gap: 32px;
    column-gap: 32px;
    grid-row-gap: 32px;
    row-gap: 32px;
    grid-template-areas: "report activity" "orders sales";
    /* grid-template-areas: "report report" "activity sales" "orders orders";  tablet */
}

.card {
    /* border: 1px solid #c8cbcc; */
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(55, 71, 79, 0.15);
    border-radius: 5px;
    padding: 15px;
    background-color: #ffffff;
}

.cardTitle {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
}

.cardSecondTitle {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

.cardSecondTitle:hover {
    text-decoration: underline;
    cursor: pointer;
}

.cardHeader {
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
}

.cardBody {
    height: 85%;
    overflow-y: auto;
}

.salesTable {
    width: 100%;
    /* height: 100%; */
}

th,
td {
    border-bottom: 1px solid #ddd;
}

td {
    height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

thead {
    width: calc(100% - 0.8em) !important;
}

tbody {
    display: block;
    height: 21vh;
}

thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.tableHeaderItem {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.tableBodyItem {
    color: #37474f;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.salesSelect {
    color: #37474f;
    font-size: 14px;
    width: 160px;
}

.dateContainer {
    display: flex;
    align-items: center;
}

.noData {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #37474f;
}

.containerMobileOrders {
    color: #37474F;
}

.line {
    border: 0.5px solid #C8CBCC;
    opacity: 0.4;
}

.ordersItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    font-size: 14px;
}

.ordersItemHeader .number span {
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 3px;
}

.ordersItemHeader .image {
    cursor: pointer;
}

.ordersItemHeader .isRotate {
    transform: rotate(180deg);
}

.ordersItemBody {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin: 5px 10px;
    font-size: 12px;
}

.ordersItemBody .title {
    text-transform: uppercase;
    font-weight: 700;
    width: 47px;
}

.ordersItemBody .info {
    margin-left: 89px;
}

@media screen and (max-width: 480px) {
    .containerDesktop {
        margin-left: 20px;
        margin-right: 20px;
    }
    .welcomeText {
        font-size: 24px;
    }
    .pageTitle {
        font-size: 14px;
    }
    .cardHeader {
        margin-top: 15px;
    }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
    .gridContainer {
        grid-template-columns: auto;
        /* grid-template-rows: 274px 274px; */
        grid-column-gap: 20px;
        column-gap: 20px;
        grid-row-gap: 20px;
        row-gap: 20px;
        grid-template-areas: "report" "activity" "sales" "orders";
        margin-bottom: 40px;
    }
    .pageTitle {
        margin-bottom: 5px;
    }
    .welcomeText {
        margin-bottom: 20px;
    }
    .date {
        display: none;
    }
    .cardTitle {
        font-size: 18px;
    }
}


/**/

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .containerDesktop {
        margin-left: 40px;
        margin-right: 40px;
    }
    .pageTitle {
        font-size: 17px;
    }
    .welcomeText {
        font-size: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
    .gridContainer {
        grid-template-columns: 334px 334px;
        /* grid-template-rows: 274px 274px; */
        grid-column-gap: 20px;
        column-gap: 20px;
        grid-row-gap: 20px;
        row-gap: 20px;
        grid-template-areas: "report report" "activity sales" "orders orders";
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .gridContainer {
        grid-template-columns: 460px 460px;
        /* grid-template-rows: 274px 274px; */
        grid-column-gap: 20px;
        column-gap: 20px;
        grid-row-gap: 20px;
        row-gap: 20px;
        grid-template-areas: "report report" "activity sales" "orders orders";
    }
}
#root {
    height: 100%;
}

html,
body {
    height: 100%;
}

.homeContainer {
    min-height: 100vh;
}

.inventoryContainer {
    margin: 0 40px;
    height: 100%;
}

.inventoryContainer .inventoryTitle {
    color: #37474f;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
}

.inventoryFilter {
    display: grid;
    grid-template-columns: auto 180px;
    margin-top: 20px;
}

.inventoryLeft {
    display: flex;
    align-items: center;
    width: 324px;
}

.inventoryBtn {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #ff6d00;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.inventoryBtn.primary-lg {
    width: 100%;
    height: 44px;
}

.inventoryBtn.primary {
    width: 72px;
    height: 36px;
}

.inventoryBtn.secondary {
    background-color: transparent;
    color: #37474f;
    height: 36px;
}

.inventoryBtn.minWidth {
    min-width: 55px;
    margin-right: 0 !important;
}

.inventoryBtn:hover {
    opacity: 0.9;
}

.inventoryBtnIcon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    font-weight: bold;
}

.inventoryNotFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.inventoryNotFoundContainer {
    width: 180px;
    margin-top: 128px;
    text-align: center;
    font-weight: normal;
}

.inventoryNotFoundTittle {
    font-size: 20px;
    margin-bottom: 32px;
}

.select {
    display: flex;
    align-items: center;
}

.select h2 {
    color: #37474f;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 12px;
}

.select select {
    width: 180px;
    height: 38px;
    border: 1px solid #c8cbcc;
    border-radius: 5px;
    color: #6c7980;
    -webkit-appearance: none;
            appearance: none;
    background: url(/static/media/arrowSelect.73c16702.svg) no-repeat right;
    background-position-x: 155px;
    text-indent: 8px;
    font-size: 16px;
}

.select select>option {
    background: #fff;
    color: #6c7980;
}

.search {
    position: relative;
    margin-right: 32px;
}

.searchIcon {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 12px;
    bottom: 0;
    left: 8px;
    color: #6c7980;
    font-weight: bold;
}

.search input[type="text"] {
    font-size: 16px;
    border: 1px solid #c8cbcc;
    text-indent: 25px;
    border-radius: 5px;
    font-weight: 400;
}

.tableHeaderItemInv {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.tableBodyItemInv {
    color: #37474f;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.inventoryTable {
    height: calc(100vh - 400px);
    overflow-y: auto;
}

.inventoryTable td {
    height: 80px;
}

.inventoryTable {
    margin-top: 64px;
    color: #37474f;
}

.inventoryTable .imagen {
    width: 60px;
    height: 60px;
    background-color: #c8cbcc;
}

.inventoryTable .imagen img {
    width: 100%;
    height: 100%;
    background-color: #c8cbcc;
}

.inventoryTable .comentProduct {
    display: flex;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
}

.inventoryTable .comentProduct .comentProductIcon {
    width: 10px;
    height: 10px;
    margin-right: 6px;
}

.inventoryTable .comentProduct span {
    font-size: 14px;
}

.category {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 8px;
}

.categoryItem {
    height: 24px;
    border: 1px solid #c8cbcc;
    border-radius: 4px;
    text-align: center;
    margin: 5px 0;
    padding: 2px;
}

.categoryItem span {
    font-size: 14px;
}

.tableActions {
    display: flex;
    justify-content: space-evenly;
}

table tbody tr:hover {
    background-color: #c8cbcc;
    cursor: pointer;
}

thead {
    width: 100% !important;
}

tbody {
    height: 100%;
}

.paperbin {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.containerMobileInventory {
    margin-top: 20px;
    color: #37474f;
    height: calc(100vh - 370px);
    overflow: auto;
}

.inventaryItemHeaderFather {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    font-size: 14px;
}

.inventaryItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0;
    font-size: 14px;
}

.product-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-info:nth-child(2),
.product-info:nth-child(3) {
    margin-top: 5px;
}

.producto-name p {
    width: 190px;
    font-style: 14px;
    font-weight: 700;
}

.product-img img {
    width: 58px;
    height: 58px;
    margin-right: 16px;
}

.product {
    width: 280px;
}

.inventaryItemHeader .product-sku p {
    font-weight: 600;
    font-size: 14px;
}

.pagi {
    background-color: red;
}

@media screen and (min-width: 320px) and (max-width: 320px) {
    .select select {
        width: 186px;
        background-position-x: 160px;
    }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
    .producto-name p,
    .producto-status,
    .producto-price {
        width: 210px;
    }
    .select {
        width: 98%;
    }
    .select select {
        width: inherit;
        background-position-x: 205px;
    }
}

@media screen and (max-width: 480px) {
    .inventoryContainer {
        margin: 0 20px;
    }
    .inventoryFilter {
        grid-template-columns: auto;
    }
    .inventoryContainer .inventoryTitle {
        font-size: 18px;
        margin-top: 15px;
    }
    .headerOptions {
        margin-top: 20px;
    }
    .headerOptions ul {
        display: inline;
    }
    .headerOptions ul li {
        margin: 0;
        font-size: 14px;
        padding: 5px 10px;
        margin-right: 15px;
    }
    .inventoryBtn.primary-lg {
        width: 140px;
        height: 30px;
        justify-content: space-around;
    }
    .positionAddProd {
        position: absolute;
        top: 78px;
        right: 0;
    }
    .scrollOptions {
        overflow-x: auto;
        white-space: nowrap;
    }
    .scrollOptions::-webkit-scrollbar {
        display: none;
    }
    .headerOptions ul li p {
        margin-top: 5px;
    }
    .headerOptions ul li.isActive {
        width: auto;
        height: 30px;
        background-color: rgba(255, 109, 0, 0.2);
        border-radius: 5px;
    }
    .inventoryLeft {
        width: auto;
    }
    .category {
        grid-template-columns: auto auto;
        grid-column-gap: 4px;
    }
    .tableActions {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .inventoryBtn.primary {
        width: 133px;
    }
    .image {
        width: 17px;
    }
    .producto-favorite,
    .inventoryTable .comentProduct {
        font-size: 22px;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .searchText {
        width: inherit;
    }
    .inventoryLeft {
        align-items: flex-start;
        flex-direction: column;
    }
    .search {
        width: 98%;
        margin-right: 0;
        margin-bottom: 12px;
    }
}

@media screen and (min-width: 411px) and (max-width: 767px) {
    .select select {
        background-position-x: 240px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .tableHeaderItemInv {
        font-size: 12px;
    }
    .tableBodyItemInv {
        font-size: 14px;
    }
    .tableBodyItemInv:nth-child(2) p>* {
        display: block;
    }
    .tableActions {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .inventoryBtn {
        font-size: 12px;
    }
    .inventoryBtn.secondary {
        margin-right: 0;
        margin-top: 5px;
        /* border: 1px solid red; */
    }
    .category {
        grid-template-columns: auto;
    }
    .categoryItem {
        margin: 5px 8px;
    }
    .select select {
        width: 230px;
        background-position-x: 205px;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
    .paperbin {
        font-size: 9px;
    }
}
.main-container {
    margin: 0 40px;
    height: 100%;
}

.title-orders {
    color: #37474f;
    font-size: 28px;
    font-weight: 700;
}


/* .option-text {
    color: #6C7980;
    margin-left: 15px;
    cursor: pointer;
} */

.option-text:nth-child(1) {
    margin-left: 0;
}

.option-selected {
    color: #ff6d00;
}

.search-container {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
}

.custom-input {
    border: 1px solid #c8cbcc;
    border-radius: 5px;
    padding: 5px 10px;
    outline: none;
    height: 30px;
}

.column-text {
    color: #37474f;
    font-weight: bold;
    display: flex;
    flex-basis: 25%;
    align-items: center;
}

.column-text:nth-child(2),
.column-text:nth-child(3) {
    margin-left: 32px;
}

.column-text span {
    margin-right: 25px;
}

.filterStatus {
    width: 100%;
}

.table-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
}

.table-column {
    display: flex;
    width: 100%;
    margin-top: 2%;
    border-bottom: 1px solid #c8cbcc;
    color: #37474f;
    justify-content: space-between;
}

.column-name {
    display: flex;
    flex-basis: 14%;
    max-width: 14%;
    justify-content: center;
}

.table-rows {
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #37474f;
    font-size: 15px;
    cursor: pointer;
}

.table-row {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #c8cbcc;
    margin-top: 1%;
    justify-content: space-between;
}

.row-data {
    display: flex;
    flex-basis: 14%;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.actionsOrders {
    display: flex;
}

.select-order-type {
    height: 25px;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    justify-content: center;
}

.pagination>.active>a {
    color: #ff6d00;
}

.pagination>li>a {
    padding: 5px 10px;
    cursor: pointer;
}

.statusOrders {
    margin-left: 8px;
}

.btn-orders-primary {
    border: none;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #ff6d00;
    font-weight: bold;
    font-size: 14px;
    border-radius: 4px;
    cursor: pointer;
    padding: 5px;
}

.containerMobileOrders {
    color: #37474f;
}

.cardMobileOrders {
    width: auto;
    height: auto;
    background: #ffffff;
    box-shadow: 0px 4px 20px rgba(55, 71, 79, 0.15);
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 24px;
}

.order-info p {
    font-size: 14px;
    cursor: pointer;
}

.order-info p span {
    font-weight: 700;
    text-transform: uppercase;
}

.itemContainerOrders {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px 0 10px 0;
}

@media screen and (min-width: 320px) and (max-width: 320px) {
    .custom-input {
        width: 110px;
    }
    .order-actions,
    .order-data {
        width: 122px;
    }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
    .custom-input {
        width: revert;
    }
    .order-actions,
    .order-data {
        width: 170px;
    }
}

@media screen and (max-width: 480px) {
    .main-container {
        margin: 0 20px;
    }
    .scrollOptions {
        overflow-x: auto;
        white-space: nowrap;
    }
    .scrollOptions::-webkit-scrollbar {
        display: none;
    }
    .column-text span {
        display: none;
    }
    .column-text:nth-child(1) {
        flex-basis: 100%;
        margin-bottom: 20px;
    }
    .column-text:nth-child(2) {
        margin-right: 20px;
    }
    .column-text:nth-child(2),
    .column-text:nth-child(3) {
        margin-left: 0;
        flex-basis: 43%;
    }
    .actionsOrders {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .btn-orders-primary {
        margin: 0;
        width: 122px;
        margin-bottom: 8px;
    }
    .statusOrders {
        font-size: 14px;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {}

@media screen and (min-width: 411px) and (max-width: 767px) {
    .custom-input {
        width: 160px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .column-text span {
        margin-right: 10px;
    }
    .column-text:nth-child(1) {
        flex-basis: 27%;
    }
    .column-text:nth-child(2) {
        flex-basis: 36%;
        margin-left: 15px;
    }
    .column-text:nth-child(3) {
        margin-left: 15px;
    }
    .column-name {
        flex-basis: 25%;
    }
    .column-name:nth-child(3) {
        min-width: 25%;
    }
    .column-name:nth-child(6) {
        flex-basis: 40%;
    }
    .row-data {
        font-size: 14px;
    }
    .row-data:nth-child(3) {
        flex-basis: 18%;
        /* border: 1px solid red; */
    }
    .row-data:nth-child(4) {
        flex-basis: 13%;
    }
    .actionsOrders {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .statusOrders {
        margin-left: 4px;
        font-size: 13px;
    }
    .btn-orders-primary {
        margin: 0;
        margin-bottom: 7px;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {}
button{
    margin-right: 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #6C7980;
    text-transform: uppercase;
    transition:all ease-in-out .2s;
  }

.active{
    color: #FF6D00;
}
@keyframes ldio-3b1ir8li0qi {
    0% { opacity: 1 }
    100% { opacity: 0 }
  }
  
  .loadingDiv{
    position: fixed;
    z-index: 99;
    top:0; 
    bottom: 0;
    left: 0; 
    right: 0; 
    background: #000000CD;
  }
  .ldio-3b1ir8li0qi div {
    left: 94px;
    top: 48px;
    position: absolute;
    animation: ldio-3b1ir8li0qi linear 1s infinite;
    background: #ee6123;
    width: 12px;
    height: 24px;
    border-radius: 6px / 12px;
    transform-origin: 6px 52px;
  }.ldio-3b1ir8li0qi div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -0.9166666666666666s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -0.8333333333333334s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.75s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.6666666666666666s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.5833333333333334s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.5s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.4166666666666667s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.3333333333333333s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.25s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.16666666666666666s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.08333333333333333s;
    background: #ee6123;
  }.ldio-3b1ir8li0qi div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
    background: #ee6123;
  }
  .loadingio-spinner-spinner-i7ozunbmji {
    width: 200px;
    height: 200px;
    overflow: hidden;
    background: #f1f2f3;
    position: fixed;
    left: 50%;
    margin-left: -100px;
    top: 50%;
    margin-top: -100px;
    border-radius:40px;
    z-index: 99;
    
  }
  .ldio-3b1ir8li0qi {
    width: 100%;
    height: 100%;
    position: relative;
    transform: translateZ(0) scale(1);
    -webkit-backface-visibility: hidden;
            backface-visibility: hidden;
    transform-origin: 0 0; /* see note above */
  }
  .ldio-3b1ir8li0qi div { box-sizing: content-box; }
  /* generated by https://loading.io/ */
/* Customize the label (the container) */

.container {
    display: flex;
    position: relative;
    padding-left: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    user-select: none;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #37474F;
    flex-direction: row;
    align-items: center;
    height: 0px;
}


/* Hide the browser's default checkbox */

.container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}


/* Create a custom checkbox */

.checkmark {
    position: absolute;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: #FFFFFF;
    border-radius: 3px;
    border: 1px solid #37474F;
}


/* On mouse-over, add a grey background color */

.container:hover input~.checkmark {
    background-color: #ccc;
}


/* When the checkbox is checked, add a blue background */

.container input:checked~.checkmark {
    background-color: #37474F;
}


/* Create the checkmark/indicator (hidden when not checked) */

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}


/* Show the checkmark when checked */

.container input:checked~.checkmark:after {
    display: block;
}


/* Style the checkmark/indicator */

.container .checkmark:after {
    left: 5px;
    top: 1px;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}
.modal-container {
    background: #FFFFFF;
    border-radius: 5px;
    padding: 42px;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.proforma-link-text {
    width: 65%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.modal-image-container {
    display: flex;
    justify-content: center;
}

.modal-close-icon {
    position: absolute;
    right: 42px;
    width: 14px;
    cursor: pointer;
}
.changePassword-general-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0px 15px 15px 15px;
}

.changePassword-title {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    white-space: nowrap;
    -webkit-user-select: none;
            user-select: none;
}

.changePassword-icon {
    margin-top: -5px;
}

.changePassword-input-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    width: 100%;
}

.changePassword-input-label {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.changePassword-input {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
    padding: 0px 10px;
}

.changePassword-button-container {
    display: flex;
    justify-content: flex-end;
}

.changePassword-button {
    background: #FF6D00;
    border: 0;
    box-shadow: 0px 2px 8px rgb(9 128 225 / 10%);
    border-radius: 4px;
    padding: 5px 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    transition: 0.3s;
    margin: 0px;
}

.changePassword-button:hover {
    cursor: pointer;
    background: #999999;
}
.accordion {
    display: flex;
    flex-direction: column;
    margin: 15px 0px;
    width: -webkit-min-content;
    width: min-content;
}

.accordion-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
}

.accordion-content {
    display: flex;
}
.mainContainerProfile {
    margin-left: 32px;
}

.generalInfoProfile {
    grid-area: item-1;
}

.publishContainerProfile {
    height: calc(100vh - 200px);
    width: 60%;
}

.sectionSubTitleProfile {
    font-size: 16px;
    font-weight: 400;
    color: #37474F;
}

.topContainerProfile {
    display: grid;
    grid-template-columns: 840px 300px;
    grid-column-gap: 150px;
    grid-template-areas: "item-1 item-2";
}

.topContainerProfile:nth-child(3),
.topContainerProfile:nth-child(4) {
    margin-bottom: 52px;
}

.perfileTitle {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 48px;
}

.notificationOptions {
    display: flex;
    flex-direction: column;
}

.inputContainerCheck {
    margin-top: 4vh;
}

.circularSquare {
    width: 197px;
    height: 197px;
    border-radius: 50%;
}

.containerPhotoPerfile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 215px;
}

.btnPhoto {
    color: #37474f;
    font-size: 14px;
    margin-top: 20px;
}

.containerPassword {
    display: flex;
    justify-content: center;
}

.btnPassword {
    width: 400px;
    background-color: #c8cbcc;
    color: white;
    margin-top: 32px;
    height: 44px;
    border-radius: 4px;
    margin-bottom: 64px;
}

.containerOptionsDelevery {
    margin: 16px 0px;
}

.btnMethodAdd {
    height: 32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    width: 100%;
}

.btnMethodAdd span {
    color: #FFFFFF;
    font-size: 14px;
    text-transform: uppercase;
}

.isNotActive {
    background-color: #C8CBCC;
    cursor: not-allowed;
}

.methodAdd {
    margin-top: 16px;
}

.listAdd {
    margin-top: 16px;
    margin-bottom: 40px;
}

.cartAdd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: 76px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(55, 71, 79, 0.15);
    border-radius: 5px;
    padding: 0 16px;
    margin-top: 16px;
}

.cartInfo {
    color: #37474F;
    font-size: 14px;
}

.cartInfo .description {
    font-weight: 600;
}

.cartInfo .price {
    font-weight: 400;
}

.cartActions {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.cartActions .edit {
    display: flex;
    font-size: 14px;
    font-weight: 900;
    color: #FF6D00;
    margin-right: 24px;
    text-transform: uppercase;
}

.edit span {
    margin-right: 10px;
}

.containerActiosEdit {
    display: flex;
    height: 52px;
    margin-top: 16px;
}

.containerActiosEdit button {
    font-weight: 700;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 5px;
}

.containerActiosEdit .save {
    color: #FFFFFF;
}

.containerActiosEdit .cancel {
    color: #37474F;
}

.editMethod {
    padding: 16px;
    box-shadow: 0px 0px 6px rgb(55 71 79 / 15%);
    border-radius: 5px;
}

.commerce-title {
    white-space: nowrap;
    -webkit-user-select: none;
            user-select: none;
}

.set-commerce-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0px 15px 15px 15px;
}

.commerce-input-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    width: 100%;
}

.commerce-input {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
    padding: 0px 10px;
}

.set-commerce-btn-container {
    display: flex;
    justify-content: flex-end;
}

.set-commerce-btn {
    background: #FF6D00;
    border: 0;
    box-shadow: 0px 2px 8px rgb(9 128 225 / 10%);
    border-radius: 4px;
    padding: 5px 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    transition: 0.3s;
    margin: 0px;
}

.set-commerce-btn:hover {
    cursor: pointer;
    background: #999999;
}

.profile-container {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;
}

.select-image-btn {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    color: #6c7980;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 5px 10px;
    margin: 4px;
    resize: none;
    cursor: pointer;
}

.container-icon-image-profile {
    background: #37474f;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.5%;
    margin-left: 1%;
}

.icon-image-profile {
    font-size: 100px;
    color: #ffffff;
}

@media screen and (min-width: 320px) and (max-width: 320px) {}

@media screen and (min-width: 320px) and (max-width: 768px) {}

@media screen and (max-width: 480px) {
    .mainContainerProfile {
        margin: 0 20px;
    }
    .listAdd {
        margin-bottom: 0;
    }
    .topContainerProfile {
        grid-template-columns: 100%;
        grid-template-areas: "item-2  " "item-1 ";
        grid-row-gap: 16px;
    }
    .topContainerProfile:nth-child(2),
    .topContainerProfile:nth-child(3) {
        margin-bottom: 0;
    }
    .topContainerProfile:nth-child(4) .generalInfoProfile,
    .topContainerProfile:nth-child(5) .generalInfoProfile {
        margin-top: 20px;
    }
    .perfileTitle {
        margin-bottom: 36px;
    }
    .sectionSubTitleProfile {
        font-size: 14px;
    }
    .btnPassword {
        font-size: 14px;
        margin-right: 0;
        height: 30px;
    }
    .btnPassword p {
        text-align: center;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {}

@media screen and (min-width: 411px) and (max-width: 767px) {}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .containerPassword {
        justify-content: end;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
    .topContainerProfile {
        grid-template-columns: 360px 300px;
        grid-column-gap: 50px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .topContainerProfile {
        grid-template-columns: 540px 320px;
        grid-column-gap: 90px;
    }
}
#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.containerHome {
    width: 83%;
    overflow-y: hidden;
}

.homeContainer {
    display: flex;
    width: 100%;
    min-height: 100%;
    height: 100vh;
    overflow: hidden;
}

.headerHome {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 48px 0 32px;
}

.h-hamgur,
.h-logo {
    display: none;
}

.h-close {
    position: fixed;
    top: 15px;
    left: 345px;
    right: 0;
    font-size: 25px;
    color: #37474F;
}

.sideBarContainer {
    width: 17%;
    border-right: 1px solid #c8cbcc;
    padding-top: 3vh;
    left: -100vw;
}

.kakaoLogoContainer {
    display: flex;
    justify-content: center;
}

.kakaoLogo {
    width: 75%;
    margin-top: 2vh;
}

.optionsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5vh;
}

.activeOption {
    background: rgba(255, 110, 0, 0.08);
    border-left: 8px solid #ff6d00;
    padding-left: 22.6% !important;
}

.menuOption {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #37474f;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    padding-left: 25%;
    height: 48px;
}

.optionIcon {
    margin-bottom: 4px;
    margin-right: 10px;
}

.inventaryOption {
    width: 80%;
    display: flex;
    align-items: center;
}

.topBar {
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.userProfileCircle {
    width: 48px;
    height: 48px;
    background: #37474f;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.5%;
    margin-left: 1%;
}

.userProf {
    font-size: 26px;
    color: #ffffff;
}

.newProductButton {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    border-radius: 4px;
    color: #37474f;
}

.newProductButton:hover {
    background: #ff6d00;
    color: #ffffff;
}

.userDropdownOptions {
    display: none;
    position: absolute;
    min-width: 160px;
    padding: 12px 16px;
    z-index: 1;
    right: 24px;
    width: 11.25%;
}

.userDropdown {
    margin-right: 2.5%;
    margin-left: 1%;
}

.userDropdown:hover .userDropdownOptions {
    display: block;
}

.dropdownArrow {
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
}

.innerDropdownOptions {
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    background-color: white;
    margin-top: -1px;
    padding: 10px 5px;
}

.logoutOption {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.dropdownOptionText {
    color: #37474f;
    font-weight: bold;
    text-transform: uppercase;
}

.containerFavorite {
    width: 600px;
    height: 500px;
    border: 1px solid #c8cbcc;
    background-color: #fff;
    padding: 20px 10px;
    position: relative;
}

@media screen and (max-width: 480px) {
    .headerHome {
        margin-left: 20px;
        margin-right: 20px;
    }
    .h-logo img {
        width: 160px;
    }
    .userProfileCircle {
        width: 20px;
        height: 20px;
    }
    .userProf {
        font-size: 16px;
    }
    .containerFavorite {
        width: 280px;
    }
    .sideBarContainer {
        width: 100%;
    }
    .h-close {
        left: unset;
        right: 15px;
        font-size: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
    .sideBarContainer {
        position: fixed;
        height: 100%;
        background: rgba(255, 255, 255, 1);
        z-index: 2;
        transition: 0.6s;
    }
    .containerHome {
        width: 100%;
    }
    .userDropdown {
        margin-left: 6px;
    }
    .headerHome {
        justify-content: space-between;
    }
    .h-hamgur,
    .h-logo {
        display: block;
        cursor: pointer;
    }
    .h-hamgur {
        z-index: 3;
    }
    .kakaoLogo {
        width: 257px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .sideBarContainer {
        width: 50%;
    }
    .headerHome {
        margin: 0 40px;
    }
    .userProfileCircle {
        width: 30px;
        height: 30px;
    }
    .userProf {
        font-size: 20px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .h-close {
        left: 480px;
    }
}
.mainContainer {
    margin: 0 40px;
    height: 89vh;
}

.topContainer {
    display: grid;
    grid-template-columns: 60% 30%;
    grid-template-areas: "general publish " "productDetails productDetails";
    justify-content: space-between;
    height: 46vh;
    grid-row-gap: 20px;
    row-gap: 20px;
}

.generalInfoContainer {
    grid-area: general;
    padding-right: 5px;
}

.publishContainer {
    grid-area: publish;
}

.productDetail {
    grid-area: productDetails;
    height: 36vh;
    overflow-y: auto;
    padding-right: 5px;
}

.inputContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 2vh;
}

.newProductTitle {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    text-transform: uppercase;
}

.sectionTitle {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
}

.sectionSubTitle {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-right: 12px;
}

.customInput {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    color: #6c7980;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    padding: 5px 10px;
    resize: none;
    margin-top: 5px;
}

.publishSubContainer {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 0px 20px;
    margin-top: 1vh;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    height: 37vh;
}

.selectContainer {
    display: flex;
    align-items: baseline;
}

.selectContainer2 {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
}

.publishButton {
    background: #ff6d00;
    box-shadow: 0px 2px 8px rgb(55 71 79 / 10%);
    border-radius: 4px;
    border: 0;
    width: 100%;
    color: #ffffff;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 0px;
    cursor: pointer;
}

.saveDraftButton {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    padding: 5px 0px;
    border: 0;
    width: 100%;
    background: #ffffff;
    cursor: pointer;
}

.tab-content {
    height: 23vh;
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    margin-top: -12px;
    overflow-y: auto;
}

.charCounterContainer {
    display: flex;
    justify-content: flex-end;
}

.charCounter {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    opacity: 0.5;
}

.itemContainer {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 1px 5px;
    color: #37474f;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    margin-right: 10px;
}

.itemListContainer {
    display: flex;
    overflow-x: auto;
    margin-top: 1vh;
}

.tabContainer {
    display: flex;
    justify-content: space-around;
    padding: 0px 15px 15px 15px;
}

.tabInnerContainer {
    width: 45%;
}

.checkInput {
    margin-left: 20px;
}

.dateContainer {
    display: flex;
    justify-content: space-evenly;
}

.dateSelectorContainer {
    display: flex;
    align-items: center;
}

.skuInputContainer {
    display: flex;
    align-items: center;
}

.secondImageContainer {
    width: 100px;
    height: 100px;
    border: 1px solid #c8cbcc;
    display: flex;
    align-items: center;
    justify-content: center;
}

.file {
    opacity: 0;
    width: 0.1px;
    height: 0.1px;
    position: absolute;
}

.fileInput label {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    color: #6c7980;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 5px 10px;
    resize: none;
    cursor: pointer;
}

.mainImage {
    width: 197px;
    height: 197px;
    object-fit: contain;
    border: 1px solid #c8cbcc;
    margin-top: 1vh;
}

.secondImage {
    width: 100px;
    height: 100px;
    object-fit: contain;
    border: 1px solid #c8cbcc;
}

.closeImagen {
    position: absolute;
    top: -6px;
    right: -6px;
    cursor: pointer;
    border: 1px solid #c8cbcc;
    border-radius: 100%;
    padding: 2px;
    background: #fff;
}

.galeryContainer {
    display: grid;
    grid-template-columns: 100px 100px 100px 100px;
    grid-column-gap: 10px;
    column-gap: 10px;
    grid-row-gap: 10px;
    row-gap: 10px;
    margin-top: 2vh;
}

.tabContainerSingle {
    display: flex;
    padding: 0px 15px 15px 15px;
}

.attributesContainer {
    padding: 37px 32px;
}

.subattributesContainer {
    padding: 24px 35px;
}

.helperInfoContainer {
    background-color: #ececec;
    border-color: #e0e0e0 !important;
    border: #000000 !important;
    border-radius: 10px;
    padding: 7px;
    width: 17%;
    margin-top: -25px;
}

.helperInfoContainer2 {
    background-color: #ececec;
    border-color: #e0e0e0 !important;
    border: #000000 !important;
    border-radius: 10px;
    padding: 7px;
    width: 25%;
    align-self: flex-end;
    margin-top: 2%;
}

.h6-info-X {
    margin-top: -15px;
    margin-right: -2px;
}

.continer-text-require {
    display: flex;
    align-items: center;
    align-self: baseline;
}

.container {
    width: auto;
    margin-left: 0px;
}

.helperContainer {
    display: flex;
    align-items: baseline;
}

@media screen and (min-width: 320px) and (max-width: 320px) {}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .publishSubContainer {
        height: 50vh;
    }
    .isFlexColumnG {
        flex-direction: column;
    }
    .isFlexColumnG .sectionSubTitle {
        margin-bottom: 10px;
    }
    .selectspecial {
        margin-top: 5px;
    }
    .helperContainer .sectionSubTitle {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 480px) {
    .mainContainer {
        margin: 0 20px;
    }
    .newProductTitle {
        font-size: 24px;
    }
    .sectionTitle {
        font-size: 18px;
    }
    .topContainer {
        display: grid;
        grid-template-columns: 100%;
        grid-template-areas: "general  " "productDetails " "publish";
        height: auto;
    }
    .tabContainer {
        flex-direction: column;
    }
    .tabInnerContainer {
        width: 100%;
    }
    .isFlexColumn {
        flex-direction: column;
    }
    .isFlexColumn .sectionSubTitle {
        margin-bottom: 10px;
    }
    .selectContainer2 {
        justify-content: start;
    }
    .tab-buttons {
        overflow-x: auto;
        white-space: nowrap;
        margin-bottom: 20px;
    }
    .tab-buttons::-webkit-scrollbar {
        display: none;
    }
    .tab-buttons button {
        font-size: 16px;
        padding: 4px 10px;
        margin-right: 10px;
    }
    .productDetail,
    .tab-content {
        height: auto;
        overflow-y: initial;
    }
    .itemListContainer {
        flex-wrap: wrap;
    }
    .productOuts {
        margin: 10px 0;
    }
    .sectionSubTitle {
        font-size: 12px;
    }
    .inputContainer .sectionSubTitle {
        margin-right: 0;
    }
    .bgActive {
        /* border: 1px solid red; */
        height: 30px;
        background-color: rgba(255, 109, 0, 0.2);
        border-radius: 5px;
    }
    .galeryContainer {
        grid-template-columns: 100px 100px;
    }
    .itemContainer {
        margin-top: 5px;
    }
    .helperInfoContainer {
        width: 100%;
        margin-top: 10px;
    }
    .subattributesContainer {
        padding: 12px 12px;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {}

@media screen and (min-width: 411px) and (max-width: 767px) {}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .topContainer {
        grid-template-columns: 60% 35%;
    }
    .tab-content {
        margin-top: -10px;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
    .tab-buttons button {
        font-size: 16px;
    }
    .helperInfoContainer {
        width: 27%;
    }
}
.main-container {
    margin: 0 40px;
    height: 90vh;
}

.title-orders {
    color: #37474f;
    font-size: 28px;
    font-weight: 700;
}

.second-header-container {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    justify-content: space-between;
}

.search-bar {
    border: 1px solid #c8cbcc;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-search-input {
    border: none;
    width: 90%;
    outline: none;
}

.complementary-text {
    color: #37474f;
    font-weight: bold;
}

.second-header-inner-container {
    display: flex;
    width: 23%;
    justify-content: space-evenly;
    align-items: center;
}

.main-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FF6D00;
    box-shadow: 0px 2px 8px rgba(9, 128, 225, 0.1);
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}

.copy-link-text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FF6D00;
    margin-left: 8px;
}

.copy-link-icon {
    color: #FF6D00;
}

.copy-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-proforma-icon {
    color: #FFFFFF;
    font-size: 20px;
}

@media screen and (max-width: 480px) {
    .main-container {
        margin: 0 20px;
    }
    .scrollOptions {
        overflow-x: auto;
        white-space: nowrap;
    }
    .scrollOptions::-webkit-scrollbar {
        display: none;
    }
}
.main-container {
    margin: 0 40px;
    height: 90vh;
}

.main-title {
    color: #37474f;
    font-size: 28px;
    font-weight: 700;
}

.second-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: #37474F;
}

.inner-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #37474F;
}

.disabled-main-action-button {
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
    background: #C8CBCC;
    padding: 12px;
}

.info-input {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    color: #6C7980;
    outline: none;
}

.info-input-required {
    border: 1px solid #FF6D00;
}

.info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.add-product-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border: 2px solid #6C7980;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #37474F;
}

.add-product-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-container {
    display: flex;
    flex-direction: column;
}

.input-container-large {
    width: 60%;
}

.input-container-medium {
    width: 45%;
}

.input-container-small {
    width: 30%;
}

.save-button-container {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    margin-bottom: 20px;
}

.max-height-table {
    overflow: auto;
    height: calc(100vh - 580px);
    min-height: 80px;
}

.upload-photo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 5px;
}

.upload-photo-input {
    display: none;
}

.product-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-row {
    padding: 8px 0px;
    margin-top: 0 !important;
}

.quantity-helper-button {
    background: #F0F1F2;
    border-radius: 2px;
    padding: 0px 10px;
    height: 35px;
    margin: 0;
}

.small-info-input {
    width: 25%;
    margin: 0px 6px;
}

.table-header-text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #37474F;
}
.second-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 30px;
    border: 1px solid #6C7980;
    box-sizing: border-box;
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #37474F;
    cursor: pointer;
}

.button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.client-info-table {
    width: 45%;
}

.client-info-table > tbody > tr > td {
    border-bottom: 0;
}

.max-height-table-review {
    overflow: auto;
    height: calc(100vh - 660px);
    min-height: 80px;
}

.total-text {
    font-weight: bold;
    font-size: 20px;
    color: #FF6D00;
}

.resetPassword-background-container {
    background: linear-gradient(116.82deg, #37474F 0%, #1F2526 100%);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resetPassword-container {
    background: #FFFFFF;
    border-radius: 4px;
    width: 45.5%;
    height: 54.1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.resetPassword-title-container {
    display: flex;
    justify-content: center;
}

.resetPassword-button {
    background: #FF6D00;
    border: 0;
    box-shadow: 0px 2px 8px rgb(9 128 225 / 10%);
    border-radius: 4px;
    padding: 10px 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    transition: 0.3s;
}

.resetPassword-button:hover {
    cursor: pointer;
    background: #999999;
}

.resetPassword-input {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
}

.resetPassword-input-label {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0px 10px;
}

.resetPassword-title-label {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
}

.resetPassword-input-container {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.resetPassword-goBack-svg {
    position: absolute;
    margin-left: -15%;
}

.resetPassword-button-container {
    width: 80%;
    display: flex;
    justify-content: flex-end;
}
