.pageTitle {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    display: block;
    margin-bottom: 20px;
}

.welcomeText {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    display: block;
    margin-bottom: 46px;
}

.item-1 {
    grid-area: report;
}

.item-2 {
    grid-area: activity;
}

.item-3 {
    grid-area: orders;
}

.item-4 {
    grid-area: sales;
}

.containerDesktop {
    margin-left: 32px;
    margin-right: 48px;
    /* overflow: visible; */
}

.gridContainer {
    display: grid;
    height: auto;
    width: 100%;
    grid-template-columns: 656px 427px;
    /*grid-template-rows: 274px 274px; */
    column-gap: 32px;
    row-gap: 32px;
    grid-template-areas: "report activity" "orders sales";
    /* grid-template-areas: "report report" "activity sales" "orders orders";  tablet */
}

.card {
    /* border: 1px solid #c8cbcc; */
    box-sizing: border-box;
    box-shadow: 0px 4px 20px rgba(55, 71, 79, 0.15);
    border-radius: 5px;
    padding: 15px;
    background-color: #ffffff;
}

.cardTitle {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
}

.cardSecondTitle {
    color: #37474f;
    text-transform: uppercase;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
}

.cardSecondTitle:hover {
    text-decoration: underline;
    cursor: pointer;
}

.cardHeader {
    height: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 17px;
}

.cardBody {
    height: 85%;
    overflow-y: auto;
}

.salesTable {
    width: 100%;
    /* height: 100%; */
}

th,
td {
    border-bottom: 1px solid #ddd;
}

td {
    height: 40px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

thead {
    width: calc(100% - 0.8em) !important;
}

tbody {
    display: block;
    height: 21vh;
}

thead,
tbody tr {
    display: table;
    width: 100%;
    table-layout: fixed;
}

.tableHeaderItem {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.tableBodyItem {
    color: #37474f;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.salesSelect {
    color: #37474f;
    font-size: 14px;
    width: 160px;
}

.dateContainer {
    display: flex;
    align-items: center;
}

.noData {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #37474f;
}

.containerMobileOrders {
    color: #37474F;
}

.line {
    border: 0.5px solid #C8CBCC;
    opacity: 0.4;
}

.ordersItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px;
    font-size: 14px;
}

.ordersItemHeader .number span {
    text-transform: uppercase;
    font-weight: 700;
    margin-right: 3px;
}

.ordersItemHeader .image {
    cursor: pointer;
}

.ordersItemHeader .isRotate {
    transform: rotate(180deg);
}

.ordersItemBody {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    margin: 5px 10px;
    font-size: 12px;
}

.ordersItemBody .title {
    text-transform: uppercase;
    font-weight: 700;
    width: 47px;
}

.ordersItemBody .info {
    margin-left: 89px;
}

@media screen and (max-width: 480px) {
    .containerDesktop {
        margin-left: 20px;
        margin-right: 20px;
    }
    .welcomeText {
        font-size: 24px;
    }
    .pageTitle {
        font-size: 14px;
    }
    .cardHeader {
        margin-top: 15px;
    }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
    .gridContainer {
        grid-template-columns: auto;
        /* grid-template-rows: 274px 274px; */
        column-gap: 20px;
        row-gap: 20px;
        grid-template-areas: "report" "activity" "sales" "orders";
        margin-bottom: 40px;
    }
    .pageTitle {
        margin-bottom: 5px;
    }
    .welcomeText {
        margin-bottom: 20px;
    }
    .date {
        display: none;
    }
    .cardTitle {
        font-size: 18px;
    }
}


/**/

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .containerDesktop {
        margin-left: 40px;
        margin-right: 40px;
    }
    .pageTitle {
        font-size: 17px;
    }
    .welcomeText {
        font-size: 30px;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
    .gridContainer {
        grid-template-columns: 334px 334px;
        /* grid-template-rows: 274px 274px; */
        column-gap: 20px;
        row-gap: 20px;
        grid-template-areas: "report report" "activity sales" "orders orders";
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .gridContainer {
        grid-template-columns: 460px 460px;
        /* grid-template-rows: 274px 274px; */
        column-gap: 20px;
        row-gap: 20px;
        grid-template-areas: "report report" "activity sales" "orders orders";
    }
}