.main-container {
    margin: 0 40px;
    height: 90vh;
}

.title-orders {
    color: #37474f;
    font-size: 28px;
    font-weight: 700;
}

.second-header-container {
    display: flex;
    flex-wrap: wrap;
    margin: 20px 0;
    justify-content: space-between;
}

.search-bar {
    border: 1px solid #c8cbcc;
    border-radius: 5px;
    padding: 5px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.custom-search-input {
    border: none;
    width: 90%;
    outline: none;
}

.complementary-text {
    color: #37474f;
    font-weight: bold;
}

.second-header-inner-container {
    display: flex;
    width: 23%;
    justify-content: space-evenly;
    align-items: center;
}

.main-action-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #FF6D00;
    box-shadow: 0px 2px 8px rgba(9, 128, 225, 0.1);
    border-radius: 5px;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FFFFFF;
    padding: 12px 30px;
    cursor: pointer;
}

.copy-link-text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #FF6D00;
    margin-left: 8px;
}

.copy-link-icon {
    color: #FF6D00;
}

.copy-link-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.new-proforma-icon {
    color: #FFFFFF;
    font-size: 20px;
}

@media screen and (max-width: 480px) {
    .main-container {
        margin: 0 20px;
    }
    .scrollOptions {
        overflow-x: auto;
        white-space: nowrap;
    }
    .scrollOptions::-webkit-scrollbar {
        display: none;
    }
}