.mainContainerProfile {
    margin-left: 32px;
}

.generalInfoProfile {
    grid-area: item-1;
}

.publishContainerProfile {
    height: calc(100vh - 200px);
    width: 60%;
}

.sectionSubTitleProfile {
    font-size: 16px;
    font-weight: 400;
    color: #37474F;
}

.topContainerProfile {
    display: grid;
    grid-template-columns: 840px 300px;
    grid-column-gap: 150px;
    grid-template-areas: "item-1 item-2";
}

.topContainerProfile:nth-child(3),
.topContainerProfile:nth-child(4) {
    margin-bottom: 52px;
}

.perfileTitle {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 28px;
    text-transform: uppercase;
    margin-bottom: 48px;
}

.notificationOptions {
    display: flex;
    flex-direction: column;
}

.inputContainerCheck {
    margin-top: 4vh;
}

.circularSquare {
    width: 197px;
    height: 197px;
    border-radius: 50%;
}

.containerPhotoPerfile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 215px;
}

.btnPhoto {
    color: #37474f;
    font-size: 14px;
    margin-top: 20px;
}

.containerPassword {
    display: flex;
    justify-content: center;
}

.btnPassword {
    width: 400px;
    background-color: #c8cbcc;
    color: white;
    margin-top: 32px;
    height: 44px;
    border-radius: 4px;
    margin-bottom: 64px;
}

.containerOptionsDelevery {
    margin: 16px 0px;
}

.btnMethodAdd {
    height: 32px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    width: 100%;
}

.btnMethodAdd span {
    color: #FFFFFF;
    font-size: 14px;
    text-transform: uppercase;
}

.isNotActive {
    background-color: #C8CBCC;
    cursor: not-allowed;
}

.methodAdd {
    margin-top: 16px;
}

.listAdd {
    margin-top: 16px;
    margin-bottom: 40px;
}

.cartAdd {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: auto;
    height: 76px;
    background-color: #FFFFFF;
    box-shadow: 0px 0px 6px rgba(55, 71, 79, 0.15);
    border-radius: 5px;
    padding: 0 16px;
    margin-top: 16px;
}

.cartInfo {
    color: #37474F;
    font-size: 14px;
}

.cartInfo .description {
    font-weight: 600;
}

.cartInfo .price {
    font-weight: 400;
}

.cartActions {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.cartActions .edit {
    display: flex;
    font-size: 14px;
    font-weight: 900;
    color: #FF6D00;
    margin-right: 24px;
    text-transform: uppercase;
}

.edit span {
    margin-right: 10px;
}

.containerActiosEdit {
    display: flex;
    height: 52px;
    margin-top: 16px;
}

.containerActiosEdit button {
    font-weight: 700;
    font-size: 14px;
    padding: 6px 12px;
    border-radius: 5px;
}

.containerActiosEdit .save {
    color: #FFFFFF;
}

.containerActiosEdit .cancel {
    color: #37474F;
}

.editMethod {
    padding: 16px;
    box-shadow: 0px 0px 6px rgb(55 71 79 / 15%);
    border-radius: 5px;
}

.commerce-title {
    white-space: nowrap;
    user-select: none;
}

.set-commerce-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 300px;
    padding: 0px 15px 15px 15px;
}

.commerce-input-container {
    display: flex;
    flex-direction: column;
    margin: 10px 0px;
    width: 100%;
}

.commerce-input {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
    padding: 0px 10px;
}

.set-commerce-btn-container {
    display: flex;
    justify-content: flex-end;
}

.set-commerce-btn {
    background: #FF6D00;
    border: 0;
    box-shadow: 0px 2px 8px rgb(9 128 225 / 10%);
    border-radius: 4px;
    padding: 5px 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    transition: 0.3s;
    margin: 0px;
}

.set-commerce-btn:hover {
    cursor: pointer;
    background: #999999;
}

.profile-container {
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin-bottom: 36px;
}

.select-image-btn {
    border: 1px solid #c8cbcc;
    box-sizing: border-box;
    border-radius: 2px;
    color: #6c7980;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    padding: 5px 10px;
    margin: 4px;
    resize: none;
    cursor: pointer;
}

.container-icon-image-profile {
    background: #37474f;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.5%;
    margin-left: 1%;
}

.icon-image-profile {
    font-size: 100px;
    color: #ffffff;
}

@media screen and (min-width: 320px) and (max-width: 320px) {}

@media screen and (min-width: 320px) and (max-width: 768px) {}

@media screen and (max-width: 480px) {
    .mainContainerProfile {
        margin: 0 20px;
    }
    .listAdd {
        margin-bottom: 0;
    }
    .topContainerProfile {
        grid-template-columns: 100%;
        grid-template-areas: "item-2  " "item-1 ";
        grid-row-gap: 16px;
    }
    .topContainerProfile:nth-child(2),
    .topContainerProfile:nth-child(3) {
        margin-bottom: 0;
    }
    .topContainerProfile:nth-child(4) .generalInfoProfile,
    .topContainerProfile:nth-child(5) .generalInfoProfile {
        margin-top: 20px;
    }
    .perfileTitle {
        margin-bottom: 36px;
    }
    .sectionSubTitleProfile {
        font-size: 14px;
    }
    .btnPassword {
        font-size: 14px;
        margin-right: 0;
        height: 30px;
    }
    .btnPassword p {
        text-align: center;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {}

@media screen and (min-width: 411px) and (max-width: 767px) {}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .containerPassword {
        justify-content: end;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
    .topContainerProfile {
        grid-template-columns: 360px 300px;
        grid-column-gap: 50px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .topContainerProfile {
        grid-template-columns: 540px 320px;
        grid-column-gap: 90px;
    }
}