.main-container {
    margin: 0 40px;
    height: 90vh;
}

.main-title {
    color: #37474f;
    font-size: 28px;
    font-weight: 700;
}

.second-title {
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    text-transform: uppercase;
    color: #37474F;
}

.inner-label {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #37474F;
}

.disabled-main-action-button {
    border-radius: 5px;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    font-weight: bold;
    font-size: 14px;
    background: #C8CBCC;
    padding: 12px;
}

.info-input {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 5px;
    padding: 5px 10px;
    font-size: 14px;
    color: #6C7980;
    outline: none;
}

.info-input-required {
    border: 1px solid #FF6D00;
}

.info-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
}

.add-product-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 24px;
    border: 2px solid #6C7980;
    box-sizing: border-box;
    border-radius: 5px;
    margin: 10px 20px;
    cursor: pointer;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #37474F;
}

.add-product-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.input-container {
    display: flex;
    flex-direction: column;
}

.input-container-large {
    width: 60%;
}

.input-container-medium {
    width: 45%;
}

.input-container-small {
    width: 30%;
}

.save-button-container {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    margin-bottom: 20px;
}

.max-height-table {
    overflow: auto;
    height: calc(100vh - 580px);
    min-height: 80px;
}

.upload-photo-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 60px;
    height: 60px;
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 5px;
}

.upload-photo-input {
    display: none;
}

.product-image {
    width: 60px;
    height: 60px;
    object-fit: cover;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.table-row {
    padding: 8px 0px;
    margin-top: 0 !important;
}

.quantity-helper-button {
    background: #F0F1F2;
    border-radius: 2px;
    padding: 0px 10px;
    height: 35px;
    margin: 0;
}

.small-info-input {
    width: 25%;
    margin: 0px 6px;
}

.table-header-text {
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    color: #37474F;
}