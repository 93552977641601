button{
    margin-right: 20px;
    font-size: 20px;
    font-style: normal;
    font-weight: bold;
    background: transparent;
    border: none;
    outline: none;
    cursor: pointer;
    color: #6C7980;
    text-transform: uppercase;
    transition:all ease-in-out .2s;
  }

.active{
    color: #FF6D00;
}