@import url('https://fonts.googleapis.com/css2?family=Hind:wght@400;700&display=swap');
* {
    margin: 0;
    padding: 0;
    font-family: 'Hind', sans-serif;
}


/* width */

::-webkit-scrollbar {
    width: 9px;
}


/* Track */

::-webkit-scrollbar-track {
    background: #ededed;
    border-radius: 10px;
}


/* Handle */

::-webkit-scrollbar-thumb {
    background: #C8CBCC;
    border-radius: 10px;
}


/* Handle on hover */

::-webkit-scrollbar-thumb:hover {
    background: #b6b7b8;
}

.swal2-confirm {
    background-color: #FF6D00 !important;
}

.headerOptions ul {
    list-style: none;
}

.headerOptions ul li {
    list-style: none;
    display: inline-block;
    margin-right: 20px;
    margin-top: 32px;
    font-size: 20px;
    color: #6c7980;
    font-weight: 700;
    text-transform: uppercase;
    cursor: pointer;
}

.headerOptions ul li.isActive {
    color: #ff6d00;
}

@media screen and (max-width: 480px) {
    .headerOptions {
        margin-top: 20px;
    }
    .headerOptions ul {
        display: inline;
    }
    .headerOptions ul li {
        margin: 0;
        font-size: 14px;
        padding: 5px 10px;
        margin-right: 15px;
    }
    .headerOptions ul li p {
        margin-top: 5px;
    }
    .headerOptions ul li.isActive {
        width: auto;
        height: 30px;
        background-color: rgba(255, 109, 0, 0.2);
        border-radius: 5px;
    }
}