#overlay {
    position: fixed;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 2;
    cursor: pointer;
}

.containerHome {
    width: 83%;
    overflow-y: hidden;
}

.homeContainer {
    display: flex;
    width: 100%;
    min-height: 100%;
    height: 100vh;
    overflow: hidden;
}

.headerHome {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0 48px 0 32px;
}

.h-hamgur,
.h-logo {
    display: none;
}

.h-close {
    position: fixed;
    top: 15px;
    left: 345px;
    right: 0;
    font-size: 25px;
    color: #37474F;
}

.sideBarContainer {
    width: 17%;
    border-right: 1px solid #c8cbcc;
    padding-top: 3vh;
    left: -100vw;
}

.kakaoLogoContainer {
    display: flex;
    justify-content: center;
}

.kakaoLogo {
    width: 75%;
    margin-top: 2vh;
}

.optionsContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: 5vh;
}

.activeOption {
    background: rgba(255, 110, 0, 0.08);
    border-left: 8px solid #ff6d00;
    padding-left: 22.6% !important;
}

.menuOption {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #37474f;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    padding-left: 25%;
    height: 48px;
}

.optionIcon {
    margin-bottom: 4px;
    margin-right: 10px;
}

.inventaryOption {
    width: 80%;
    display: flex;
    align-items: center;
}

.topBar {
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.userProfileCircle {
    width: 48px;
    height: 48px;
    background: #37474f;
    border-radius: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 2.5%;
    margin-left: 1%;
}

.userProf {
    font-size: 26px;
    color: #ffffff;
}

.newProductButton {
    height: 25px;
    width: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    border-radius: 4px;
    color: #37474f;
}

.newProductButton:hover {
    background: #ff6d00;
    color: #ffffff;
}

.userDropdownOptions {
    display: none;
    position: absolute;
    min-width: 160px;
    padding: 12px 16px;
    z-index: 1;
    right: 24px;
    width: 11.25%;
}

.userDropdown {
    margin-right: 2.5%;
    margin-left: 1%;
}

.userDropdown:hover .userDropdownOptions {
    display: block;
}

.dropdownArrow {
    display: flex;
    justify-content: flex-end;
    padding-right: 8px;
}

.innerDropdownOptions {
    border: 1px solid #c4c4c4;
    border-radius: 2px;
    background-color: white;
    margin-top: -1px;
    padding: 10px 5px;
}

.logoutOption {
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}

.dropdownOptionText {
    color: #37474f;
    font-weight: bold;
    text-transform: uppercase;
}

.containerFavorite {
    width: 600px;
    height: 500px;
    border: 1px solid #c8cbcc;
    background-color: #fff;
    padding: 20px 10px;
    position: relative;
}

@media screen and (max-width: 480px) {
    .headerHome {
        margin-left: 20px;
        margin-right: 20px;
    }
    .h-logo img {
        width: 160px;
    }
    .userProfileCircle {
        width: 20px;
        height: 20px;
    }
    .userProf {
        font-size: 16px;
    }
    .containerFavorite {
        width: 280px;
    }
    .sideBarContainer {
        width: 100%;
    }
    .h-close {
        left: unset;
        right: 15px;
        font-size: 20px;
    }
}

@media screen and (min-width: 320px) and (max-width: 1024px) {
    .sideBarContainer {
        position: fixed;
        height: 100%;
        background: rgba(255, 255, 255, 1);
        z-index: 2;
        transition: 0.6s;
    }
    .containerHome {
        width: 100%;
    }
    .userDropdown {
        margin-left: 6px;
    }
    .headerHome {
        justify-content: space-between;
    }
    .h-hamgur,
    .h-logo {
        display: block;
        cursor: pointer;
    }
    .h-hamgur {
        z-index: 3;
    }
    .kakaoLogo {
        width: 257px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .sideBarContainer {
        width: 50%;
    }
    .headerHome {
        margin: 0 40px;
    }
    .userProfileCircle {
        width: 30px;
        height: 30px;
    }
    .userProf {
        font-size: 20px;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1024px) {
    .h-close {
        left: 480px;
    }
}