#root {
    height: 100%;
}

html,
body {
    height: 100%;
}

.homeContainer {
    min-height: 100vh;
}

.inventoryContainer {
    margin: 0 40px;
    height: 100%;
}

.inventoryContainer .inventoryTitle {
    color: #37474f;
    font-size: 28px;
    font-weight: 700;
    text-transform: uppercase;
}

.inventoryFilter {
    display: grid;
    grid-template-columns: auto 180px;
    margin-top: 20px;
}

.inventoryLeft {
    display: flex;
    align-items: center;
    width: 324px;
}

.inventoryBtn {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #ff6d00;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
}

.inventoryBtn.primary-lg {
    width: 100%;
    height: 44px;
}

.inventoryBtn.primary {
    width: 72px;
    height: 36px;
}

.inventoryBtn.secondary {
    background-color: transparent;
    color: #37474f;
    height: 36px;
}

.inventoryBtn.minWidth {
    min-width: 55px;
    margin-right: 0 !important;
}

.inventoryBtn:hover {
    opacity: 0.9;
}

.inventoryBtnIcon {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    font-weight: bold;
}

.inventoryNotFound {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.inventoryNotFoundContainer {
    width: 180px;
    margin-top: 128px;
    text-align: center;
    font-weight: normal;
}

.inventoryNotFoundTittle {
    font-size: 20px;
    margin-bottom: 32px;
}

.select {
    display: flex;
    align-items: center;
}

.select h2 {
    color: #37474f;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 12px;
}

.select select {
    width: 180px;
    height: 38px;
    border: 1px solid #c8cbcc;
    border-radius: 5px;
    color: #6c7980;
    appearance: none;
    background: url("../../assets/arrowSelect.svg") no-repeat right;
    background-position-x: 155px;
    text-indent: 8px;
    font-size: 16px;
}

.select select>option {
    background: #fff;
    color: #6c7980;
}

.search {
    position: relative;
    margin-right: 32px;
}

.searchIcon {
    position: absolute;
    width: 12px;
    height: 12px;
    top: 12px;
    bottom: 0;
    left: 8px;
    color: #6c7980;
    font-weight: bold;
}

.search input[type="text"] {
    font-size: 16px;
    border: 1px solid #c8cbcc;
    text-indent: 25px;
    border-radius: 5px;
    font-weight: 400;
}

.tableHeaderItemInv {
    color: #37474f;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
}

.tableBodyItemInv {
    color: #37474f;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
}

.inventoryTable {
    height: calc(100vh - 400px);
    overflow-y: auto;
}

.inventoryTable td {
    height: 80px;
}

.inventoryTable {
    margin-top: 64px;
    color: #37474f;
}

.inventoryTable .imagen {
    width: 60px;
    height: 60px;
    background-color: #c8cbcc;
}

.inventoryTable .imagen img {
    width: 100%;
    height: 100%;
    background-color: #c8cbcc;
}

.inventoryTable .comentProduct {
    display: flex;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
}

.inventoryTable .comentProduct .comentProductIcon {
    width: 10px;
    height: 10px;
    margin-right: 6px;
}

.inventoryTable .comentProduct span {
    font-size: 14px;
}

.category {
    display: grid;
    grid-template-columns: auto auto auto;
    grid-column-gap: 8px;
}

.categoryItem {
    height: 24px;
    border: 1px solid #c8cbcc;
    border-radius: 4px;
    text-align: center;
    margin: 5px 0;
    padding: 2px;
}

.categoryItem span {
    font-size: 14px;
}

.tableActions {
    display: flex;
    justify-content: space-evenly;
}

table tbody tr:hover {
    background-color: #c8cbcc;
    cursor: pointer;
}

thead {
    width: 100% !important;
}

tbody {
    height: 100%;
}

.paperbin {
    display: flex;
    flex-direction: column;
    font-size: 12px;
}

.containerMobileInventory {
    margin-top: 20px;
    color: #37474f;
    height: calc(100vh - 370px);
    overflow: auto;
}

.inventaryItemHeaderFather {
    display: flex;
    align-items: flex-start;
    margin: 10px 0;
    font-size: 14px;
}

.inventaryItemHeader {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin: 10px 0;
    font-size: 14px;
}

.product-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.product-info:nth-child(2),
.product-info:nth-child(3) {
    margin-top: 5px;
}

.producto-name p {
    width: 190px;
    font-style: 14px;
    font-weight: 700;
}

.product-img img {
    width: 58px;
    height: 58px;
    margin-right: 16px;
}

.product {
    width: 280px;
}

.inventaryItemHeader .product-sku p {
    font-weight: 600;
    font-size: 14px;
}

.pagi {
    background-color: red;
}

@media screen and (min-width: 320px) and (max-width: 320px) {
    .select select {
        width: 186px;
        background-position-x: 160px;
    }
}

@media screen and (min-width: 321px) and (max-width: 768px) {
    .producto-name p,
    .producto-status,
    .producto-price {
        width: 210px;
    }
    .select {
        width: 98%;
    }
    .select select {
        width: inherit;
        background-position-x: 205px;
    }
}

@media screen and (max-width: 480px) {
    .inventoryContainer {
        margin: 0 20px;
    }
    .inventoryFilter {
        grid-template-columns: auto;
    }
    .inventoryContainer .inventoryTitle {
        font-size: 18px;
        margin-top: 15px;
    }
    .headerOptions {
        margin-top: 20px;
    }
    .headerOptions ul {
        display: inline;
    }
    .headerOptions ul li {
        margin: 0;
        font-size: 14px;
        padding: 5px 10px;
        margin-right: 15px;
    }
    .inventoryBtn.primary-lg {
        width: 140px;
        height: 30px;
        justify-content: space-around;
    }
    .positionAddProd {
        position: absolute;
        top: 78px;
        right: 0;
    }
    .scrollOptions {
        overflow-x: auto;
        white-space: nowrap;
    }
    .scrollOptions::-webkit-scrollbar {
        display: none;
    }
    .headerOptions ul li p {
        margin-top: 5px;
    }
    .headerOptions ul li.isActive {
        width: auto;
        height: 30px;
        background-color: rgba(255, 109, 0, 0.2);
        border-radius: 5px;
    }
    .inventoryLeft {
        width: auto;
    }
    .category {
        grid-template-columns: auto auto;
        grid-column-gap: 4px;
    }
    .tableActions {
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
    }
    .inventoryBtn.primary {
        width: 133px;
    }
    .image {
        width: 17px;
    }
    .producto-favorite,
    .inventoryTable .comentProduct {
        font-size: 22px;
    }
}

@media screen and (min-width: 320px) and (max-width: 768px) {
    .searchText {
        width: inherit;
    }
    .inventoryLeft {
        align-items: flex-start;
        flex-direction: column;
    }
    .search {
        width: 98%;
        margin-right: 0;
        margin-bottom: 12px;
    }
}

@media screen and (min-width: 411px) and (max-width: 767px) {
    .select select {
        background-position-x: 240px;
    }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .tableHeaderItemInv {
        font-size: 12px;
    }
    .tableBodyItemInv {
        font-size: 14px;
    }
    .tableBodyItemInv:nth-child(2) p>* {
        display: block;
    }
    .tableActions {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .inventoryBtn {
        font-size: 12px;
    }
    .inventoryBtn.secondary {
        margin-right: 0;
        margin-top: 5px;
        /* border: 1px solid red; */
    }
    .category {
        grid-template-columns: auto;
    }
    .categoryItem {
        margin: 5px 8px;
    }
    .select select {
        width: 230px;
        background-position-x: 205px;
    }
}

@media screen and (min-width: 768px) and (max-width: 768px) {
    .paperbin {
        font-size: 9px;
    }
}