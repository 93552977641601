.headerOptions ul li.isActive {
  color: #ff6d00;
}

.headerOptions ul {
  list-style: none;
}

.headerOptions ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
  margin-top: 32px;
  font-size: 20px;
  color: #6c7980;
  font-weight: 700;
  text-transform: uppercase;
  cursor: pointer;
}
