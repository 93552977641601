.resetPassword-background-container {
    background: linear-gradient(116.82deg, #37474F 0%, #1F2526 100%);
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.resetPassword-container {
    background: #FFFFFF;
    border-radius: 4px;
    width: 45.5%;
    height: 54.1%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
}

.resetPassword-title-container {
    display: flex;
    justify-content: center;
}

.resetPassword-button {
    background: #FF6D00;
    border: 0;
    box-shadow: 0px 2px 8px rgb(9 128 225 / 10%);
    border-radius: 4px;
    padding: 10px 20px;
    color: #FFFFFF;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    transition: 0.3s;
}

.resetPassword-button:hover {
    cursor: pointer;
    background: #999999;
}

.resetPassword-input {
    border: 1px solid #C8CBCC;
    box-sizing: border-box;
    border-radius: 2px;
    height: 35px;
}

.resetPassword-input-label {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    text-transform: uppercase;
    padding: 0px 10px;
}

.resetPassword-title-label {
    color: #37474F;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    text-transform: uppercase;
}

.resetPassword-input-container {
    display: flex;
    flex-direction: column;
    width: 70%;
}

.resetPassword-goBack-svg {
    position: absolute;
    margin-left: -15%;
}

.resetPassword-button-container {
    width: 80%;
    display: flex;
    justify-content: flex-end;
}